import { AddressLine } from '../../address/AddressLine';
import { CourierPriceList } from '../../courierPriceList/CourierPriceList';
import { Bank } from '../../ParmsGeneral/bank/Bank';
import { Territories } from '../../views/territories/Territories';
import { EntityTypeIdentification } from '../entityTypeIdentification/EntityTypeIdentification';

export class Entity {
  code: number;
  id: string;
  fullName: string;
  email: string;
  phone1: string;
  referenceAlt: string;
  typeCustomer: any | null;
  typeIdentification: any | null;
  originCountry: string;
  address: AddressLine | any | null;
  addressEntityList: AddressLine[];
  contacts: any[];
  incoterms: any | null;
  placePerformance: string;
  currency: any | null;
  termsPayment: any | null;
  delivery: string;
  bank: Bank | null;
  groupAgentService: any | null;
  termsPaymentAdditional: any | null;
  reconciliationAccount: string;
  paymentBlock: string;
  branchCode: string;
  paymentMethods: any | null;
  vendorNumber: string;
  searchTerm1: string;
  searchTerm2: string;
  taxId: string;
  fein: string;
  commercialNumber: string;
  accountNumber: string;
  prefix: string;
  discountPercentage: number;
  company: string;
  otherTypeIdentificationEntityList: EntityTypeIdentification[];
  idCode: number | null;
  courierPriceList: CourierPriceList | null;
  freeDays: number;

  public constructor() {
    this.code = 0;
    this.id = '';
    this.fullName = '';
    this.email = '';
    this.phone1 = '';
    this.referenceAlt = '';
    this.typeCustomer = null;
    this.typeIdentification = null;
    this.originCountry = '';
    this.address = new AddressLine();
    this.contacts = [];
    this.incoterms = null;
    this.placePerformance = '';
    this.currency = null;
    this.termsPayment = null;
    this.delivery = '';
    this.bank = new Bank();
    this.groupAgentService = null;
    this.termsPaymentAdditional = null;
    this.reconciliationAccount = '';
    this.paymentBlock = '';
    this.branchCode = '';
    this.paymentMethods = null;
    this.vendorNumber = '';
    this.searchTerm1 = '';
    this.searchTerm2 = '';
    this.taxId = '';
    this.fein = '';
    this.commercialNumber = '';
    this.addressEntityList = [];
    this.accountNumber = '';
    this.prefix = '';
    this.company = '';
    this.discountPercentage = 0;
    this.otherTypeIdentificationEntityList = [];
    this.idCode = null;
    this.courierPriceList = null;
    this.freeDays = 0;
  }

  public mapAddressFromTerritory(territory: Territories) {
    this.address.country = territory.country;
    this.address.state = territory.state;
    this.address.city = territory.city;
    this.address.countryName = territory.countryName;
    this.address.stateName = territory.stateName;
    this.address.cityName = territory.cityName;
    return this;
  }
}
